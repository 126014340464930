html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
  --blue-sky: #2168e1;
  --blue-sky-light: #3196E8;
  --raspberry-pink: #E61E6E;
  --blue-sky-95: #e9f0fc;
  --light-grey: #DFDFDF;
  --light-baby-blue: #3196E826;
  --mint-green-light: #298A6F30;
  --mint-green: #35B0BE
}
.h-100 {
  height: 100%;
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.text-right {
  text-align: right!important;
}

.color-grey {
  color: #bdbfc7;
}

.list-overview {
  .list-element {
    display: inline-block;
  }

  .list-element:not(:last-child) {
    border-right: 1px solid #bdbfc7;
    padding-right: 30px;
  }

  .list-element:not(:first-child) {
    padding-left: 30px;
  }
}

.box-overview {
  padding: 25px 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}

.status-badge {
  border-radius: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

// Material
mat-paginator {
  background-color: transparent;
}

.blue-color{
  color: var(--blue-sky)
}

.page-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.5rem 1rem;
  gap: 10px;
  box-sizing: border-box;
  align-items: center;
}

.buttons-container{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  padding: 20px 0;
}

.blue-sky-color {
  color: var(--blue-sky)
}

.blue-sky-light-color {
  color: var(--blue-sky-light)
}

.done-blue{
  background-color: var(--blue-sky-light);
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0.5rem  #8cc4f2,
  0 0 0 1rem #d1e8fa
;

  .mat-icon{
    font-size: 3rem;
    height: unset;
    width: unset;
  }
}
.icon-disclaimer {
  border-radius: 50%;
  background-color: var(--blue-sky);
  color: white;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

.title-blue {
  text-align: center;
  font: normal normal 900 24px/30px Roboto;
  letter-spacing: 0;
  color: var(--blue-sky);
  opacity: 1;
}

.primary-blue-button {
  @extend .mat-button !optional;

  color: var(--blue-sky) !important;

  &:hover {
    background-color: var(--blue-sky-95) !important;
  }
}

.white-blue-button {
  @extend .mat-button !optional;

  color: var(--blue-sky) !important;
  background-color: white !important;

  &:hover {
    background-color: var(--blue-sky-95) !important;
  }
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
}
.primary-blue-raised-button {
  @extend .mat-raised-button !optional;
  background-color: var(--blue-sky) !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
  text-align: center !important;
  letter-spacing: 1.6px !important;

  &:hover {
    background-color: var(--blue-sky-light) !important;
  }

  &[disabled] {
    background-color: #dfdfdf !important;
  }

  &.only-icon {
    .mat-icon {
      margin-right: 0;
    }
  }
}

.secondary-pink-button {
  @extend .mat-raised-button !optional;
  background-color: var(--raspberry-pink) !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
  text-align: center !important;
  letter-spacing: 1.6px !important;

  &:hover {
    background-color: #eb4789 !important;
  }

  &[disabled] {
    background-color: #ff90b8 !important;
  }

  &.only-icon {
    .mat-icon {
      margin-right: 0;
    }
  }
}

.custom-input-active-border{
  ::ng-deep {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      color: white;
    }
    mat-form-field {
      .mat-hint, input, ::placeholder, .mat-form-field-label {
        color: white;
      }
    }
  }
}

.text-center {
  text-align: center!important;
}


.coke-form {
  .form-group {
    width: 100%;
    margin-bottom: 30px;

    label {
      text-align: center;
      font-size: 1rem;
      letter-spacing: 0.12px;
      color: var(--blue-sky);
      opacity: 1;
      width: 100%;
      display: block;
    }

    .mat-form-field {
      width: 100%;
      text-align: center;

      input {
        text-align: center;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0;
        color: var(--blue-sky);
        opacity: 1;
      }
    }
  }

  .form {
    .mat-form-field {
      width: 100%;
      text-align: left;

      input {
        text-align: center;
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0;
        color: var(--blue-sky);
        opacity: 1;
      }
    }
  }
}

.mdc-snackbar {
  &.danger {
    .mdc-snackbar__surface {
      background-color: var(--mat-form-field-error-text-color)!important;
    }
  }
}

mat-stepper {
  --mat-stepper-header-selected-state-icon-background-color: var(--blue-sky);
  --mat-stepper-header-done-state-icon-background-color: var(--blue-sky);
  --mat-stepper-header-edit-state-icon-background-color: var(--blue-sky);
}
