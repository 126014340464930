@import "styles/general";

// upload card for coke module
.blue-card {
  background: transparent linear-gradient(138deg, #FFFFFF 0%, #DFDFDF 100%) 0 0 no-repeat padding-box;
  border: 1px solid #3196E8;
  border-radius: 14px;
  opacity: 1;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1;

  .card-button {
    @extend .mat-raised-button !optional;
    background-color: var(--light-baby-blue);
    color: var(--blue-sky);
    text-align: center;
    letter-spacing: 1.6px;
    border: 1px solid var(--blue-sky);
    border-radius: 16px;

    &:hover {
      background-color: white;
    }
  }
}

.upload-file-card {
  @extend .blue-card;
  background: transparent linear-gradient(138deg, #FFFFFF 0%, #FFFFFF 100%) 0 0 no-repeat padding-box;
  border: 1px solid #DFDFDF;

  .card-button {
    @extend .mat-raised-button !optional;
    background-color: var(--mint-green-light);
    color: var(--mint-green);
    text-align: center;
    letter-spacing: 1.6px;
    border: 1px solid var(--mint-green);
    border-radius: 16px;

    &:hover {
      background-color: white;
    }
  }
}

/* Datatables */
.dt-layout-start label {
  margin-left: 10px;
}

.dt-layout-end label {
  margin-right: 10px;
}
table.dataTable td.dt-left,
table.dataTable th.dt-left{
  text-align: left!important;
}

/* Width */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}
